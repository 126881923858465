import React, { Fragment } from "react"
import '../../assets/css/main.css'
import './style.css'
import SectionTitle from '../SectionTitle/SectionTitle'

const LabelClasses = [
  "uppercase",
  "font-bold",
  "text-xs",
  "tracking-wider"
].join(" ")

const InputClasses = [
  "border",
  "border-black",
  "block",
  "p-2",
  "w-full",
  "rounded-none",
  "mt-3"
].join(" ")

const Contact = () => (
  <>
    <div className="contact-bg">
      <SectionTitle title="Contact" id="Contact"/>
      <div className="max-w-5xl mx-auto p-8" id="contact">
        <div className="lg:grid lg:grid-cols-2">
          <div className="pb-12 text-center lg:text-left">
            <h3 className="freight-big-light text-5xl pb-5">Connect with us.</h3>
            <p className="tracking-wider">
              <strong>Michael Pasuit</strong>, Advertising Director
              <br />
              <a className="color-blue" href="mailto:mpasuit@foreignaffairs.com?subject=Advertising%20Info%20Request">mpasuit@foreignaffairs.com</a>
              <br />
              212.434.9528
            </p>
          </div>
          <div>
            {/*
            <form>
              <div className="grid grid-cols-2 gap-4 pb-6">
                <fieldset>
                  <label className={ LabelClasses } htmlFor="name">Name</label>
                  <input type="text" className={ InputClasses } id="name" placeholder="John Smith" />
                </fieldset>
                <fieldset>
                  <label className={ LabelClasses } htmlFor="phone">Phone Number</label>
                  <input type="tel" className={ InputClasses } id="phone" placeholder="(XXX) XXX-XXXX" />
                </fieldset>
              </div>
              <fieldset className="pb-6">
                <label className={ LabelClasses } htmlFor="email">Email</label>
                <input type="email" className={ InputClasses } id="email" placeholder="name@provider.com" />
              </fieldset>
              <fieldset className="pb-6">
                <label className={ LabelClasses } htmlFor="message">Message</label>
                <textarea className={ InputClasses + " message-text" } id="message" rows="10"></textarea>
              </fieldset>
              <input type="submit" value="Get in Touch" className="block p-2 px-4 sm:px-10 contact-cta font-bold text-white text-sm mt-2 mb-4" />
            </form>
            */}
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Contact
 