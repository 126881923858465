import React from "react"
import Slider from "react-slick";
import SectionTitle from '../SectionTitle/SectionTitle'
import './styles.css'
import "slick-carousel/slick/slick.css";
import '../../assets/css/main.css'
import RightArrowIcon from "../../../static/svg/right-arrow.svg"
import LeftArrowIcon from "../../../static/svg/left-arrow.svg"

const HistorySlider = () => {
  const slides = [
    {
        "node": {
            "id": "bfa00e1f-382c-52e0-a03a-47897370aa1a",
            "title": "Worlds of Color",
            "body": "<em>Foreign Affairs</em> was early to recognize the profound influence of social issues on global events. The famed civil rights activist and sociologist explores how the problem of “the color line” is entwined with several nations’ histories. ",
            "author": "W.E.B. DuBois",
            "image": "/img1.png",
            "contentA": "Since 1922, <em>Foreign Affairs</em> readers have been thoughtful, ambitious, and influential—and have trusted us as the world’s leading publication for sharp analysis of important global issues.",
            "contentB": "Our content comes directly from experts in their fields, providing unrivaled insight into global trends and current events. When business innovators, heads of state, and policy makers want to reach the people who matter, they choose to publish here. And when the leaders of today seek to understand the forces shaping our world, they turn to us.",
            "year": "1925"
        }
    },
    {
        "node": {
            "id": "46224784-9f6c-5412-b8fd-579d2a4d3200",
            "title": "Sources of Soviet Conduct",
            "body": "Writing anonymously as “X,” the Moscow-based diplomat outlined the policy of containment that defined the U.S.-Soviet relationship for the better half of a century.",
            "author": "George F. Kennan ",
            "image": "/img2.jpg",
            "contentA": null,
            "contentB": null,
            "year": "1947"
        }
    },
    {
        "node": {
            "id": "de5ba914-7bca-502a-9b40-db33f530f33e",
            "title": "India and the World",
            "body": "International heads of state are frequent contributors to the magazine. Here, Gandhi discusses the origins and objectives of India’s nonalignment policy in the decades following independence. ",
            "author": "Indira Gandhi",
            "image": "/img3.jpg",
            "contentA": null,
            "contentB": null,
            "year": "1972"
        }
    },
    {
        "node": {
            "id": "46a0c7c4-93ed-56d9-9d1d-0ea79b50bcb9",
            "title": "Energy Strategy: The Road Not Taken?",
            "body": "A renowned scientist makes an economic, environmental, and geopolitical case for a shift toward sustainable energy sources. <em>Foreign Affairs</em> would continue to be ahead of the industry, examining issues such as fracking nearly 40 years later. ",
            "author": "Amory Lovins",
            "image": "/img4.jpg",
            "contentA": null,
            "contentB": null,
            "year": "1976"
        }
    },
    {
        "node": {
            "id": "500f16b7-58aa-5f9c-940e-6d0045d274c4",
            "title": "The Clash of Civilizations?",
            "body": "Huntington controversially predicted that conflict in the post-Cold War world would be driven by clashes along cultural and religious lines. This article sparked animated debate both at the time of publication and in the wake of modern terror attacks. ",
            "author": "Samuel P. Huntington",
            "image": "/img5.jpg",
            "contentA": null,
            "contentB": null,
            "year": "1993"
        }
    },
    {
        "node": {
            "id": "acf80543-ce55-5473-8324-cd43dbb02f8f",
            "title": "South Africa’s Future Foreign Policy",
            "body": "With the dismantling of the apartheid regime near completion, Mandela outlined the foreign policy priorities of a democratic South Africa, including an emphasis on international human rights. ",
            "author": "Nelson Mandela ",
            "image": "/img6.jpg",
            "contentA": null,
            "contentB": null,
            "year": "1993"
        }
    },
    {
        "node": {
            "id": "4501eafa-a8f8-5fdf-afe7-991e80afa684",
            "title": "The Tiananmen Papers",
            "body": "<em>Foreign Affairs</em> released previously unpublished transcripts of the confidential meetings in which Chinese leaders deliberated how to handle the student-led protests that swept the country in the spring of 1989. The government opted for a crackdown, and hundreds, if not more, were killed in Beijing’s Tiananmen Square. ",
            "author": "Andrew J. Nathan ",
            "image": "/img7.jpg",
            "contentA": null,
            "contentB": null,
            "year": "2001"
        }
    },
    {
        "node": {
            "id": "557020f2-cbe7-5b41-bdf6-518630c5355b",
            "title": "Renewing American Leadership & Rising to a New Generation of Global Challenges",
            "body": "Before each U.S. presidential election, <em>Foreign Affairs</em> invites candidates to outline their foreign policy proposals in our pages. The July/August 2007 issue included articles from party frontrunners Barack Obama and Mitt Romney. ",
            "author": "Barack Obama & Mitt Romney",
            "image": "/img8.jpg",
            "contentA": null,
            "contentB": null,
            "year": "2007"
        }
    },
    {
        "node": {
            "id": "dad25a43-e8ba-5a4b-ac4a-55d6a3241735",
            "title": "The Digital Disruption",
            "body": "Google executives discuss how the free flow of information will transform the global distribution of power—and how that change will both provide opportunities and pose dangers.",
            "author": "Eric Schmidt and Jared Cohen",
            "image": "/img9.jpg",
            "contentA": null,
            "contentB": null,
            "year": "2010"
        }
    },
    {
        "node": {
            "id": "24b80bd7-d02f-51c3-a378-b2b57a305d9d",
            "title": "Fixing Finance",
            "body": "In the aftermath of the 2008 financial crash, the Financial Times journalist considers the roles of Wall Street and government in addressing the growing income inequality gap. ",
            "author": "Gillian Tett",
            "image": "/img10.png",
            "contentA": null,
            "contentB": null,
            "year": "2012"
        }
    },
    {
        "node": {
            "id": "0ae58207-4bf6-52a2-b185-1c9f52aade5f",
            "title": "Gene Editing for Good",
            "body": "The Microsoft founder writes that CRISPR and other gene-editing technology has the potential to help humanity overcome its greatest public health and development challenges. ",
            "author": "Bill Gates ",
            "image": "/img11.jpg",
            "contentA": null,
            "contentB": null,
            "year": "2018"
        }
    },
    {
        "node": {
            "id": "a28b9aee-44ad-5972-8506-c7e6dc7cadcf",
            "title": "A New Americanism",
            "body": "The award-winning Harvard historian surveys the many attempts over the centuries to define what it means to be an American, arguing that the United States needs a new nationalism if it is to weather illiberal assaults on its democracy. ",
            "author": "Jill Lepore ",
            "image": "/img12.jpg",
            "contentA": null,
            "contentB": null,
            "year": "2019"
        }
    },
    {
        "node": {
            "id": "2406c1d8-4c56-5e1c-b82c-6c546ff0e882",
            "title": "Making Cyberspace Safe for Democracy",
            "body": "Rosenberger, who currently serves on the Biden administration's National Security Council, discusses how the United States can compete with and defend itself against authoritarian adversaries in cyberspace. ",
            "author": "Laura Rosenberger ",
            "image": "/img13.jpg",
            "contentA": null,
            "contentB": null,
            "year": "2020"
        }
    }
  ]


  const settings = {
    customPaging: function(i) {
      return (
        <div className="history-slider__date">{slides[i].node.year}</div>
      );
    },
    dots: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <div>
      <img src={RightArrowIcon}/>
    </div>,
    prevArrow: <div>
      <img src={LeftArrowIcon}/>
    </div>,
    responsive: [
      {
        breakpoint: 592,
        settings: {
          slidesToShow: 1,
          dots: false,
          fade: true,
          className: "history-slider--sm",
        }
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 1,
          dots: false,
          fade: true,
          className: "history-slider--sm history-slider--md",
        }
      },
    ]
  };

  return (
    <div id="history">
      <SectionTitle title="History" id="History"/>
      <div className="history-slider__content px-8 freight-big-light max-w-5xl mx-auto container">
        <p>Since 1922, <i>Foreign Affairs</i> readers have been thoughtful, ambitious, and influential—and have trusted us as the world’s leading
           publication for sharp analysis of important global issues.
        </p>
        <p>Our content comes directly from experts in their fields, providing unrivaled insight into global trends and current events.
           When business innovators, heads of state, and policy makers want to reach the people who matter, they choose to publish here.
           And when the leaders of today seek to understand the forces shaping our world, they turn to us.
        </p>
      </div>

      <Slider className="history-slider" {...settings}>
        {slides.map((slide, i) =>
          <div className="history-slider__card" key={i}>
            <div className="history-slider__date-wrapper">
              <div className="history-slider__date">{slide.node.year}</div>
            </div>
            <img className="history-slider__image" src={slide.node.image} />
            <div className="history-slider__info">
              <h3 className="history-slider__card-title text-3xl pb-3">{slide.node.title}</h3>
              <div className="pb-4 history-slider__author">By {slide.node.author}</div>
              <div className="freight-text history-slider__body" dangerouslySetInnerHTML={{__html: slide.node.body}}></div>
            </div>
          </div>
        )}
      </Slider>
    </div>
  )

}



export default HistorySlider
