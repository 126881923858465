import React from "react"
import '../../assets/css/main.css'
import './styles.css'

const SectionTitle = (props) => (
    <div className="max-w-6xl mx-auto mb-12">
        <h2 className="homepage-subhead font-bold neue-haas uppercase tracking-widest text-sm pt-7" id={props.title}>
            {props.title}
        </h2>
    </div>
)

export default SectionTitle
