import React, { useState, Fragment } from "react"
import SectionTitle from '../SectionTitle/SectionTitle'
import '../../assets/css/main.css'
import "./styles.css"

const Contributors = () => {

  const [activeTag, setActiveTag] = useState("");


  const contributorsList = [
    {
        "node": {
            "id": "8cce3e57-8532-594a-a415-83714d2b0b02",
            "tags": [
                "Defense & Security",
                "Foreign Policy",
                "Politics & Society"
            ],
            "authors": "Fareed Zakaria"
        }
    },
    {
        "node": {
            "id": "1bdd3a33-9f9f-5305-8085-086b160aa906",
            "tags": [
                "Politics & Society"
            ],
            "authors": "Jill Lepore"
        }
    },
    {
        "node": {
            "id": "4cd6f177-c3fd-5470-8f0a-888fad8d61c0",
            "tags": [
                "Science & Technology",
                "Global Health Policy",
                "Cybersecurity"
            ],
            "authors": "Bill Gates"
        }
    },
    {
        "node": {
            "id": "01efbf3d-b427-5357-9db4-798cdcc53b34",
            "tags": [
                "Foreign Policy",
                "Politics & Society",
                "Defense & Security"
            ],
            "authors": "Joe Biden"
        }
    },
    {
        "node": {
            "id": "16a46155-d6a6-5300-92e0-c43e4da4469a",
            "tags": [
                "Foreign Policy",
                "Human Rights"
            ],
            "authors": "Nelson Mandela"
        }
    },
    {
        "node": {
            "id": "d8d06a38-3cc9-5d43-8249-dc1a44b62a9c",
            "tags": [
                "Politics & Society",
                "Defense & Security"
            ],
            "authors": "Samuel P. Huntington"
        }
    },
    {
        "node": {
            "id": "05512587-48e2-5d4d-b30b-189b4bd53748",
            "tags": [
                "Foreign Policy",
                "Politics & Society",
                "Defense & Security"
            ],
            "authors": "Barack Obama"
        }
    },
    {
        "node": {
            "id": "9ae59f20-8943-5ada-a572-26a0109b3483",
            "tags": [
                "Global Health Policy",
                "Economics"
            ],
            "authors": "Michael Osterholm"
        }
    },
    {
        "node": {
            "id": "1d1529ce-5532-5972-abcd-61a65c20d2a3",
            "tags": [
                "Foreign Policy",
                "Politics & Society",
                "Defense & Security"
            ],
            "authors": "Hillary Clinton"
        }
    },
    {
        "node": {
            "id": "0d55a3cd-0b54-5509-9903-c1be941bbfff",
            "tags": [
                "Politics & Society",
                "Foreign Policy"
            ],
            "authors": "Stacey Abrams"
        }
    },
    {
        "node": {
            "id": "2eb7cdd9-53d8-505c-a84e-8b4ef490f596",
            "tags": [
                "Foreign Policy",
                "Human Rights",
                "Defense & Security"
            ],
            "authors": "Henry Kissinger"
        }
    },
    {
        "node": {
            "id": "d72740bc-ecf7-5e86-aac3-8d2f4dad184d",
            "tags": [
                "Human Rights",
                "Politics & Society"
            ],
            "authors": "W. E. B. Du Bois"
        }
    },
    {
        "node": {
            "id": "723361c2-236c-506f-9eec-ab6c680d6eff",
            "tags": [
                "Politics & Society",
                "Foreign Policy"
            ],
            "authors": "George F. Kennan"
        }
    },
    {
        "node": {
            "id": "9d2d2071-eb1d-5d16-bc0e-4e1675b55e0d",
            "tags": [
                "Foreign Policy",
                "Economics"
            ],
            "authors": "George Packer"
        }
    },
    {
        "node": {
            "id": "amory",
            "tags": [
                "Science & Technology",
                "Climate Change",
                "Energy"
            ],
            "authors": "Amory Lovins"
        }
    },
    {
        "node": {
            "id": "a28bb82f-ccfb-533c-b262-b22370760622",
            "tags": [
                "Foreign Policy",
                "Politics & Society",
                "Economics",
                "Human Rights"
            ],
            "authors": "Indira Gandhi"
        }
    },
    {
        "node": {
            "id": "8d10b273-6610-5da3-b4ec-58ad7476c547",
            "tags": [
                "Science & Technology",
                "Cybersecurity",
                "Finance & Trade",
                "Economics"
            ],
            "authors": "Jared Cohen"
        }
    },
    {
        "node": {
            "id": "f65e8712-75e1-5f99-9256-ada8a2f338cd",
            "tags": [
                "Defense & Security",
                "Foreign Policy"
            ],
            "authors": "Colin Powell"
        }
    },
    {
        "node": {
            "id": "55637e1e-c18a-5b32-8f21-045c4a66b8d0",
            "tags": [
                "Politics & Society",
                "Foreign Policy",
                "Science & Technology",
                "Economics"
            ],
            "authors": "Francis Fukuyama"
        }
    },
    {
        "node": {
            "id": "7710e602-0812-56d6-b9e8-f3621e769138",
            "tags": [
                "Foreign Policy",
                "Politics & Society"
            ],
            "authors": "Cai Xia"
        }
    },
    {
        "node": {
            "id": "segal",
            "tags": [
                "Science & Technology",
                "Defense & Security",
                "Cybersecurity"
            ],
            "authors": "Adam Segal"
        }
    },
    {
        "node": {
            "id": "9d372049-4423-5b07-a425-f0ea60d2bc3f",
            "tags": [
                "Foreign Policy",
                "Defense & Security"
            ],
            "authors": "Marc Lynch"
        }
    },
    {
        "node": {
            "id": "b91f900c-085a-5870-8fdd-7f622c772eb6",
            "tags": [
                "Foreign Policy",
                "Politics & Society"
            ],
            "authors": "Mitt Romney"
        }
    },
    {
        "node": {
            "id": "50164a73-4c47-5be8-9ead-9ec2d30f1a9b",
            "tags": [
                "Foreign Policy",
                "Politics & Society"
            ],
            "authors": "Samantha Power"
        }
    },
    {
        "node": {
            "id": "larry",
            "tags": [
                "Global Health Policy"
            ],
            "authors": "Larry Brilliant"
        }
    },
    {
        "node": {
            "id": "f2b36aba-e567-5e03-baf2-6afa37b1d033",
            "tags": [
                "Foreign Policy",
                "Politics & Society"
            ],
            "authors": "John F. Kennedy"
        }
    },
    {
        "node": {
            "id": "c0f5fd94-f42a-5225-a728-4a5ebc9f463f",
            "tags": [
                "Climate Change",
                "Energy"
            ],
            "authors": "Michael Oppenheimer"
        }
    },
    {
        "node": {
            "id": "victor",
            "tags": [
                "Science & Technology",
                "Climate Change",
                "Energy"
            ],
            "authors": "David Victor"
        }
    },
    {
        "node": {
            "id": "6848765b-3d3b-5a1a-adfc-f12adbce8d5c",
            "tags": [
                "Foreign Policy"
            ],
            "authors": "Susan Rice"
        }
    },
    {
        "node": {
            "id": "0395061d-8c90-5d04-bc62-57e7052aa6ec",
            "tags": [
                "Foreign Policy",
                "Global Health Policy"
            ],
            "authors": "Laura Rosenberger"
        }
    },
    {
        "node": {
            "id": "13754ed5-e6ec-5458-b8cb-18fdb0489c09",
            "tags": [
                "Science & Technology",
                "Economics"
            ],
            "authors": "Eric Schmidt"
        }
    },
    {
        "node": {
            "id": "f190f82f-4c7e-5143-92da-32725f76a930",
            "tags": [
                "Foreign Policy",
                "Defense & Security"
            ],
            "authors": "Condoleezza Rice"
        }
    },
    {
        "node": {
            "id": "7b1dce7e-1c9c-5c81-9941-6bc7839da415",
            "tags": [
                "Foreign Policy",
                "Defense & Security"
            ],
            "authors": "Ernest Moniz"
        }
    },
    {
        "node": {
            "id": "varun",
            "tags": [
                "Science & Technology",
                "Climate Change",
                "Energy"
            ],
            "authors": "Varun Sivaram"
        }
    },
    {
        "node": {
            "id": "57427bcb-4b6e-5df6-8f00-947bbfc6747b",
            "tags": [
                "Economics",
                "Finance & Trade"
            ],
            "authors": "Klaus Schwab"
        }
    },
    {
        "node": {
            "id": "fb63d9a0-e502-5c1d-9d8b-c5c5873b5dde",
            "tags": [
                "Politics & Society",
                "Human Rights"
            ],
            "authors": "Eleanor Roosevelt"
        }
    },
    {
        "node": {
            "id": "43c8bc6e-e87c-5e4b-882a-04cbbdb08512",
            "tags": [
                "Economics",
                "Energy",
                "Finance & Trade"
            ],
            "authors": "Daniel Yergin"
        }
    },
    {
        "node": {
            "id": "694182af-068c-5b4e-abae-8fbe0c129d38",
            "tags": [
                "Foreign Policy",
                "Defense & Security"
            ],
            "authors": "Zbigniew Brzezinski"
        }
    },
    {
        "node": {
            "id": "a5e33f66-4a77-5f4c-96f2-2e276a758a14",
            "tags": [
                "Foreign Policy",
                "Defense & Security"
            ],
            "authors": "Nikita Khrushchev"
        }
    },
    {
        "node": {
            "id": "d41aeb5e-eef2-504f-abc5-46e9f9e37a5e",
            "tags": [
                "Economics",
                "Finance & Trade"
            ],
            "authors": "Gillian Tett"
        }
    },
    {
        "node": {
            "id": "bell",
            "tags": [
                "Climate Change"
            ],
            "authors": "Ruth Greenspan Bell"
        }
    },
    {
        "node": {
            "id": "1403b439-ab37-5969-87cc-5eb7e609315d",
            "tags": [
                "Economics",
                "Foreign Policy",
                "Global Health Policy"
            ],
            "authors": "Mariana Mazzucato"
        }
    },
    {
        "node": {
            "id": "acea0ca2-87a6-5120-9e9e-fcc815448d05",
            "tags": [
                "Foreign Policy",
                "Politics & Society"
            ],
            "authors": "Gamal Abdel Nasser"
        }
    },
    {
        "node": {
            "id": "00a00a8f-dcde-5465-a55d-d66e1cf9bb96",
            "tags": [
                "Economics",
                "Finance & Trade"
            ],
            "authors": "Timothy Geithner"
        }
    },
    {
        "node": {
            "id": "29a6c2e0-4daf-5061-9261-93de5a85feaa",
            "tags": [
                "Human Rights",
                "Politics & Society"
            ],
            "authors": "António Guterres"
        }
    },
    {
        "node": {
            "id": "7ee3fc7c-cc8c-5f7a-bfc4-5535e3468863",
            "tags": [
                "Foreign Policy",
                "Defense & Security",
                "Politics & Society"
            ],
            "authors": "Madeleine Albright"
        }
    },
    {
        "node": {
            "id": "stav",
            "tags": [
                "Defense & Security",
                "Cybersecurity",
                "Foreign Policy"
            ],
            "authors": "James G. Stavridis"
        }
    },
    {
        "node": {
            "id": "63e1ed20-0ccb-55f0-8028-16c1c406091c",
            "tags": [
                "Human Rights",
                "Global Health Policy",
                "Defense & Security"
            ],
            "authors": "Kofi Annan"
        }
    },
    {
        "node": {
            "id": "jaffe",
            "tags": [
                "Climate Change",
                "Energy"
            ],
            "authors": "Amy Myers Jaffe"
        }
    },
    {
        "node": {
            "id": "Rudd",
            "tags": [
                "Foreign Policy",
                "Cybersecurity"
            ],
            "authors": "Kevin Rudd"
        }
    }
]

  const data = contributorsList.map((contributor) => {
    if (typeof contributor.node.tags == "string") { 
      contributor.node.tags = contributor.node.tags.split(", ");
    }
    return contributor
  })

  const tagsArray = data.map((contributor) => {
    return contributor.node.tags
  })

  // Flatten the array of arrays https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce#flatten_an_array_of_arrays
  let tags = tagsArray.reduce(
    function(accumulator, currentValue) {
      return accumulator.concat(currentValue)
    },
    []
  )
  
  // Remove duplicate values from JS array https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
  const uniqueTags = [...new Set(tags)];

  

  return (
    <div className="contributors" id="contributors">
      <SectionTitle title="Contributors" id="Contributors"/>
      <div className="max-w-screen-xl mx-auto px-8 pb-16">
        <div className="mb-12">
          <span className="mr-7 lg:mr-12 leading-normal text-4xl md:text-5xl text-white freight-big-light font-extralight mb-10">Our coverage includes </span>
          
          {uniqueTags.map((tag, i) => 
            <span key={i} className={`inline-block mr-5 md:mr-10 leading-9 md:leading-10 cursor-pointer text-lg md:text-2xl ${tag == activeTag ? "contributors--active" : "" }`} onMouseOver={() => { setActiveTag(tag) }}>{tag}</span>
          )}

        </div>
        <div>

          {contributorsList.map((contributor, i) => 
            <span key={i} className={`inline-block mr-5 md:mr-10 leading-9 md:leading-10 freight-big tracking-wide contributors__tag text-xl md:text-3xl ${contributor.node.tags.includes(activeTag) ? "contributors--active" : "" }`}>{contributor.node.authors}</span>
          )}

        </div>
      </div>
    </div>
  )
}

export default Contributors
