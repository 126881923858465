import React from "react"
import {Helmet} from "react-helmet";
import '../assets/css/main.css'
import NavBar from '../components/NavBar/NavBar'
import Slideshow from '../components/MastheadSlideshow/MastheadSlideshow'
import HistorySlider from '../components/HistorySlider/HistorySlider'
import Contributors from '../components/Contributors/Contributors'
import Audience from '../components/Audience/Audience'
import ProductsAccordion from '../components/ProductsAccordion/ProductsAccordion'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'

const Home = ({data}) => (
  <>
    <Helmet>
      <title>Foreign Affairs Mediakit</title>
      <meta name="description" content="Foreign Affairs Media Kit provides detailed information about our advertising opportunities, audience demographics, and media reach." />
      <meta name="keywords" content="Foreign Affairs, Media Kit, Advertising, Audience, Demographics, Media Reach" />
      <meta name="author" content="Foreign Affairs Magazine" />

      <meta property="og:title" content="Foreign Affairs Media Kit" />
      <meta property="og:description" content="Detailed information about advertising opportunities, audience demographics, and media reach." />
      <meta property="og:url" content="https://mediakit.foreignaffairs.com/" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Foreign Affairs Media Kit" />
      <meta name="twitter:description" content="Detailed information about advertising opportunities, audience demographics, and media reach." />

      <link rel="canonical" href="https://mediakit.foreignaffairs.com/"></link>
    </Helmet>
    <NavBar home={true} />
    <Slideshow/>
    <HistorySlider/>
    <Contributors/>
    <Audience/>
    <ProductsAccordion/>
    <Contact />
    <Footer />
  </>
)


export default Home
