import React, { Fragment } from "react"
import '../../assets/css/main.css'
import SectionTitle from '../SectionTitle/SectionTitle'
import './styles.css'

const Audience = () => (
  <div className="audience yellow-bg" id="audience">
    <div className="max-w-7xl mx-auto pb-5">
      <SectionTitle title="Audience" id="Audience"/>
      <div className="lg:py-8">
        <div className="text-center flex flex-col lg:block">
            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">Average Income</h3>
              <div className="freight-big-light text-5xl">$430,00</div>
            </div>

            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">Influential Index</h3>
              <div className="freight-big-light text-5xl">7x</div>
            </div>

            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">Time Spent with Each Issue</h3>
              <div className="freight-big-light text-5xl">104 Minutes</div>
            </div>

            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">AVG Net Worth</h3>
              <div className="freight-big-light text-5xl">$2,600,000</div>
            </div>

            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">Print Readership</h3>
              <div className="freight-big-light text-5xl">350,000</div>
            </div>

            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">Monthly Pageviews</h3>
              <div className="freight-big-light text-5xl">3 Million</div>
            </div>

            <div className="m-auto w-64 lg:w-3/12 inline-block align-top p-7 lg:p-9 lg:my-8 audience__border min-h-full lg:h-32">
              <h3 className="uppercase bold neue-haas text-sm font-bold tracking-widest">Newsletter Subscribers</h3>
              <div className="freight-big-light text-5xl">1M+ opt-in</div>
            </div>
        </div>
        <div className="text-center">
          <a className="text-base bg-white py-2.5 my-8 px-7 color-blue mx-auto inline-block mt-3" href="/demographics" type="button">See the Numbers</a>
        </div>
      </div>
    </div>
  </div>
)

export default Audience
