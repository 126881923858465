import React, { useState, Fragment } from "react"
import '../../assets/css/main.css'
import './styles.css'
import SectionTitle from '../SectionTitle/SectionTitle'

const Slide = ({ setOpenedRow, openedRow, id, title, body, image}) => {
  return (
      <li
        className={openedRow == id ? "products-accordion--active products-accordion__item" : "" + 'products-accordion__item'}
        onClick={() => setOpenedRow()}
      >
        <div className="products-accordion__title--vertical">{title}</div>
        <div className="products-accordion__center">
          <div className="products-accordion__content">
            <div className="products-accordion__text">
              <div className="products-accordion__title">{title}</div>
              <p className="products-accordion__body freight-text" dangerouslySetInnerHTML={{__html: body}}></p>
            </div>
            <div className="products-accordion__image-wrapper">
              <img className="products-accordion__image" src={image}/>
            </div>
          </div>
        </div>
      </li>
  );
};


const ProductsAccordion = () => {

  const data = [
    {
        "node": {
            "title": "Print Magazine",
            "body": "Connect with a global audience through bimonthly issues of <i>Foreign Affairs</i>. For over a century, the physical copy of the magazine has remained the hallmark of discussion on international analysis. Our magazine offers exclusive access to influential ideas from thought leaders around the world that you can’t find anywhere else. We help leaders in their respective communities stay ahead of the curve.",
            "image": "magazines.png"
        }
    },
    {
        "node": {
            "title": "Digital Products",
            "body": "ForeignAffairs.com is here to provide context and insights into the stories shaping today's world. Engage our readers with a variety of digital opportunities tailored and targeted to specific audiences. For over a decade, our <a href='https://www.foreignaffairs.com/graduateschoolforum'>Graduate School Forum</a> has highlighted the top programs in international affairs and has been built into a dynamic resource for prospective students.",
            "image": "phone.png"
        }
    },
    {
      "node": {
        "title": "Newsletters",
        "body": "Extend your impact and reach an active readership by leveraging <i>Foreign Affairs</i> newsletters, our editor-curated reads delivered straight to the inbox of subscribers. Partner with us to amplify your message and connect with a discerning global audience. ",
        "image": "CustomOpportunitiesImage.png"
      }
    },
    {
        "node": {
            "title": "Sponsored Content",
            "body": "Craft brand-forward ideas that will resonate within global business, academia, and government through our sponsored content. We produce creative solutions for partners via a multiplatform storytelling approach rooted in well-researched and authoritative messaging. Create meaningful engagement with your brand on ForeignAffairs.com or in our print magazine.",
            "image": "group-subscription-all-access.png"
        }
    }
]
  const [openedRow, setOpenedRow] = useState(1);

  return (
    <div id="products">
      <SectionTitle title="Products" id="Products"/>
      <ul className="products-accordion">
        { data.map((product, id) =>
          <Slide
            key={product.node.title}
            setOpenedRow={() => setOpenedRow(id)}
            openedRow={openedRow}
            id={id}
            title={product.node.title}
            body={product.node.body}
            image={product.node.image}
          >
          </Slide>
        )}
      </ul>
    </div>
  )
}



export default ProductsAccordion
